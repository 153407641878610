import React from 'react'
import './header.css'
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useNavigate } from 'react-router-dom';
const Header = () => {
  const navigate=useNavigate()
  const handleAddress=()=>{
navigate('/location')
  }
  return (
    <div>
      <div className="header">
        <div className="address" style={{cursor:'pointer'}} onClick={handleAddress}>
        <LocationOnIcon/>Office #3,First Floor,Ghakar Plaza,Shakkar Shah Road,Haripur
        </div>
        <div className="email">
        <EmailIcon/>altdc@hotmail.com
        </div>
        <div className="phone-number">
        <PhoneIcon/> <a href='tel:00923135887820' ><span className='text-light'>+92 313 588 78 20</span></a>
        </div>
        <div className="social-icon">
            <ul>
                <li><FacebookIcon/></li>
                <li><InstagramIcon/> </li>
                <li><YouTubeIcon/></li>
                <li><a href='tel:00923135887820'><WhatsAppIcon/></a></li>
            </ul>
        </div>
      </div>
    </div>
  )
}

export default Header

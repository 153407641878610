import React from 'react'
import './rb.css'
const data=[
  {title:'AN11187529484',image:'/images/Rb/rb (1).jpeg'},
  {title:'Office #. 3',image:'/images/Rb/rb (1).jpg'},
  {title:'Reg #.AM-601',image:'/images/Rb/rb (1).png'},
  {title:'2023-02443',image:'/images/Rb/rb (2).jpeg'},
  {title:'NTN 7359859-1',image:'/images/Rb/rb (2).jpg'},
  {title:'850405778',image:'/images/Rb/rb (2).png'}
]

const Rb = () => {
  return (
    <div>
     <div className="main- m-5">
     <h2 className='text-danger text-center fw-bold'>We Affiliate With</h2>
     <div className="inner-rb">
      {
        data?.map((item)=>{return(
          <>
          <div className="rb-inner-div">
          <div className="rb-map">
          <img src={item.image} alt={item.image}/>
          </div>
          <div className="title">{item?.title}</div>
          </div>
          </>
        )})

      }
     </div>
     </div>
    </div>
  )
}

export default Rb

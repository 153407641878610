import React from 'react'

const Location = () => {
  return (
    <div>
      <div className="location">
        <h2 className='text-danger text-center fw-bold mechanical'>Our Location</h2>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d423411.5385238645!2d72.610679420834!3d33.99503431938775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfb2dbad289223%3A0xe7b989e12f4be25c!2sGakhar%20Plaza!5e0!3m2!1sen!2s!4v1722756300233!5m2!1sen!2s" width="100%" height="600px"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  )
}

export default Location

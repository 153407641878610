import React, { useState } from 'react'
import './Navbar.css'
import Props from '../Props/Props'
import data from '../imagesList.json'
import { NavLink, useNavigate } from 'react-router-dom'
import './Navbar.css'
const ImageBar = () => {
  const [search, setSearch]=useState('')
const navigate=useNavigate()
  const handleFilter=(e)=>{
    // alert(e)
navigate(`/image/gallery/${e}`)
// alert(e)
  }
  const handleElectrical=()=>{
    navigate(`/image/gallery/electrical`)
  }
  return (
    <div>
        <div className="nav-menu  bg-danger">

<ul className='justify-content-between'>
<button className='btn btn-warning' value='chemical' onClick={(e)=>handleFilter(e.target.value)}>Chemical</button>
<button className='btn btn-warning' onClick={handleElectrical}>Electrical(Substation)</button>
<button className='btn btn-warning' value='mechanical' onClick={(e)=>handleFilter(e.target.value)}>Mechanical</button>
<button className='btn btn-warning' value='other' onClick={(e)=>handleFilter(e.target.value)}>Others</button>

</ul></div>
<div className="images-header-div">

     </div> 
    </div>
  )
}

export default ImageBar

import React from 'react'
import './footer.css'
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link, useNavigate } from 'react-router-dom';
const Footer = () => {
  const navigate=useNavigate()
  const handleAddress=()=>{
navigate('/location')
  }
  return (
    <div>
<div className="footer bg-dark text-light">
<div className="social-logo">
  <h3>Social Links</h3>
  <div className="logo-img-footer">
    <img src='/logo2.png' alt=''/>
  </div>
  <div className="social-icon mt-5">
            <ul>
                <li><FacebookIcon/></li>
                <li><InstagramIcon/> </li>
                <li><YouTubeIcon/></li>
                <li><WhatsAppIcon/></li>
            </ul>
        </div>
</div>
<div className="quick-links">
 <h3>quick links</h3> 
<ul>
  <li><Link to='/'><button className='btn btn-warning w-50'>Home</button> </Link></li>
  <li><Link to='/about'><button className='btn btn-warning w-50'>About Us</button> </Link></li>
  <li><Link to='/location'><button className='btn btn-warning w-50'>Location</button> </Link></li>
  <li><Link to='/services'><button className='btn btn-warning w-50'>Our Services</button> </Link></li>
  {/* <li><Link to='/company'><button className='btn btn-warning w-50'>Our Compnay</button> </Link></li> */}
</ul>

</div>
<div className="information text-start">
 <h3> quick information</h3>
 <div className="address click-hover" onClick={handleAddress}>
        <LocationOnIcon/>Office #3,First Floor,Ghakar Plaza,Shakkar Shah Road,Haripur
        </div>
        <div className="email">
        <EmailIcon/>altdc@hotmail.com
        </div>
        <PhoneIcon/> <a href='tel:00923135887820' ><span className='text-light click-hover'>+92 313 588 78 20</span></a>
</div>

{/* <div className="news-letter">
<h3> news letter</h3>
</div> */}


</div>
    </div>
  )
}

export default Footer

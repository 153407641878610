import React from 'react'
import About from './About'
import OurCompnay from './OurCompnay'
import Choose from './Choose'
import Services from './Services'
import Location from './Location'
import Rb from './Rb'
import Banner from './Banner'

const Home = () => {
  return (
    <div>
      <Banner/>
      <About/>
      {/* <OurCompnay/> */}
      <Choose/>
      <Services/>
      <Rb/>
      <Location/>
     
    </div>
  )
}

export default Home

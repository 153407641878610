import React, { useEffect } from 'react'
import './about.css'
const About = () => {
useEffect(()=>{
window.scroll(0,0)
},[])

  return (
    <div>
      <div className="about">
     <div className='text-center'> <h2 className='text-danger text-uppercase m-3'>short profile of abdul Latif trading and development</h2></div>

<p className='new'>M/S abdul Latif Trading and Development is a registered firm with Fedral Board of Revnue (FBR)
 under registration # 7359859-1 since 23rd of december 2016.ATD is registered with Haripur Chamber of Commerce & Industry.
 ATD started its business since january 2017.
 Objective of ATD is to provide quality Products And Services with efficient and effective resources utilization while mentaining the best businessstandard and ethics. Prior to
 starting business in Pakistan, we have 7 years experience in saudi arabia doing business in trading and construction.
 Saudi Arabia Electric Supply Compnay was one of the main client, where we under joined following
 projects as sub-contractor.</p>
<div className='ms-5'>
 <h6 className='text-uppercase text-primary'>1. Hail-2cc simple cycle to combined cycle power plant.</h6>
 <h6 className='text-uppercase text-primary'>2. 380/132 kv ss bsp 9014</h6>
 <h6 className='text-uppercase text-primary'>3. 380/132 kv ss bsp 9018</h6>
 <h6 className='text-uppercase text-primary'>4. 380/13.8 kv sub station 8130</h6></div>
 <p className=''>
 Based in Haripur we have already established good business terms while leading organization
 of Pakistan like HMC Taxila, Pakistan Ordinance Factory Wah, and other many local industries
  and traders. in such a short span of time it is all our hard working and experience that we have acheived a recognition in market.
  we hope to play a positive role in pakistan economy by bringing competence 
  and quality in market.
  ATD has commenced an import from republic of china and deals in nin ferrous
  i-e zinc ingots, alumunium ingots and copper ingots. details of our past constract
  and constracts in saudi Arabia are mentioned below.</p>
<div className='ms-5'>
  <h6 className='text-uppercase text-primary'>1. Saudi Service For Electro Mechanical Works co. (SSEM).</h6>
  <h6 className='text-uppercase text-primary'>2. AlToukhi Company of industry, trading and contracting.</h6>
  <h6 className='text-uppercase text-primary'>3. Al Babtain Contracting Compnay.</h6>
  <h6 className='text-uppercase text-primary'>4. Nour Communication Compnay.</h6>
  </div>
  <p className='mechanical'> We confirm that our business relations will be based upon mutual respect and enhancing of betterment. We will make sure that there would be no compromise
  on quality of services on our part.</p>

<div className='text-end m-3'>
<h4 className='text-danger'>(Muhammad Zakariyya)</h4>
<h4 className='text-danger'>Chief Executive</h4>
</div>
      </div>
    </div>
  )
}

export default About

import React from 'react'

const Electrical = () => {
  return (
    <div>
<div className="mechanical">
<div className="mechanical-inner-div">
  <div className="left-div">

<div className="img-div">
  <img src='/images/electronics/crashBarriers/cb (1).jpeg' alt=''/>
</div>

  </div>

  <div className="right-div">
  <h2>We are working in a good environment with SEC/NG since 2010.</h2>
  <h3>We deal in different kinds of steel materials, some of them are mentioned below.  </h3>
  <h5>Below mentioned all material can be available with HDG according to order .We believe to deliver order in a short time ASAP .Here we expecting swift response from your side with  good news.Hope so our this journey will go very long in a good environment. Thank you
  </h5>
  <p>
<ul>

<li>All types of transformer rails.</li>
<li>Transformer Rails accessories .</li>
<li>Steel Grating (Supply & installation)</li>
<li>Access ladders </li>
<li>GIS crane Beams.</li>
<li>Crash Barriers completed set .</li>
<li>Transformer area pulling hooks.</li>
<li>Pipes for crash barriers.</li>
<li> All type & sizes of steel plates and anchor Bolts.</li>

</ul>

  </p>
  </div>
</div>


</div>
    </div>









    
  )
}

export default Electrical

import React from 'react'

const Ush = () => {
  return (
    <div>
      
<div className='d-flex flex-row text-capitalize text-center bg-primary '><h6 className='text-center'>This Website is created by  </h6> <a href='https://www.uniquesoftwarehouse.com'><h5 className='text-light'> uniquesoftwarehouse.com</h5></a>
 for mor information please click the link or dial <a href='tel:03144763232'> <h5 className='text-light'> 0092-314-476-32-32</h5></a>
</div>   
    
    </div>
  )
}

export default Ush

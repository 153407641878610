import React from 'react'
import ElectricalImagesBar from '../Navbar/ElectricalImagesBar'
import { useParams } from 'react-router-dom'
import data from '../imagesList.json'
import Props from '../Props/Props'

const ElectricalImages = () => {
    const {slug}=useParams()
  return (
    <div>
        <ElectricalImagesBar/>
        <div className="images-header-div">
{data?.filter((item)=>item.image.includes(slug))
        .map((item)=>{return(
            <>
            <div className=".images-div img">
          <Props image={item.image}/>
            </div>
            </>
        )})}

</div>
    </div>
  )
}

export default ElectricalImages

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Navbar.css'
import { Link } from 'react-router-dom';

const Navbar1=()=> {
  return (
   <>
   <div className="navbar1">
   <Link to='/' style={{cursor:'pointer'}}> <div className="logo" >
<div className="logo-img">
  <img src='/logo2.png' alt=''/>
</div>

    </div>
    </Link>
    <div className="nav-menu">
<ul className='d-flex flew-row m-2'>

  <li><Link to='/chemical'>Chemical</Link></li>
  <li><Link to='/Mechanical'>Mechanical</Link></li>
  <li><Link to='/electrical'>Electrical(Substations)</Link></li>


</ul>
</div>
<div className="image-gallery">
 <Link to='/images/gallery'><button className='btn btn-success'>Image Gallery</button></Link> 
</div>
   </div>
   </>
   
  );
}

export default Navbar1;
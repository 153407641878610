import React from 'react'
import './services.css'
import { Link } from 'react-router-dom'
const Services = () => {
  const data=[
    {name:'Chemical',image:'/sodaashlight.jpeg',details:'We Provide chemicals for industry to comprise the companies and other organizations that develop and produce industrial, specialty and other items.',link:'/chemical'},
    {name:'Mechanical',image:'/images/mechanical/mc (1).jpeg',details:'Mechanical parts trading involves sourcing and distributing essential components like bearings, gears, seals, and fasteners to various industries. These parts are crucial in maintaining and repairing machinery across sectors such as automotive, aerospace, manufacturing, and construction.',link:'/mechanical'},
    {name:'Electrical',image:'/images/electronics/crashBarriers/cb (1).jpeg',details:'We provide substation equipment, custom engineering, testing and field-ready rentals. Flexible high-voltage solutions offer multiple options for power generators, distributors and industrial consumers.',link:'/electrical'},
    // {name:'Others',image:'/images/others/others (3).jpg',details:'Detergents, Chemicals, Soaps Textile, Paper, Food Casting Industry',link:'/others'},
 
  ]
  return (
    <div>
      <div className="services mt-2 mb-2">
        <h2 className='text-danger text-center fw-bold'>Our Services</h2>
        <div className="inner-services mt-2 mb-2">
          {data?.map((item)=>{
            return(
              <>
              <Link to={item.link}>
<div className="services-map-div mt-4 mb-5">
              <div className="div-1">
<div className="image-div">
  <img src={item.image} alt=''/>
</div>

              </div>
              <div className="div-2 ">
                <div className="services-name text-center">{item.name}</div>
                <div className="details">{item.details}</div>
                <div className="read text-center"><button className='btn btn-primary'>Read More</button></div>
              </div>
              </div>
              </Link>
              </>
            )
          })}


        </div>
      </div>
      
    </div>
  )
}

export default Services

import React from 'react'
import ImageBar from './ImageBar'
import { useNavigate } from 'react-router-dom'

const ElectricalImagesBar = () => {
    const navigate=useNavigate()
    const handleFilter=(e)=>{
        // alert(e)
    navigate(`/image/gallery/electrical/${e}`)
    // alert(e)
      }
  return (
    <div>
        <ImageBar/>
        <div className="nav-menu menu">

<ul className='bg-dark'>
<button value='accessLadders' onClick={(e)=>handleFilter(e.target.value)}>Access Ladders</button>
<button value='crashBarriers' onClick={(e)=>handleFilter(e.target.value)}>Crash Barriers</button>
<button value='GISCraneBeams' onClick={(e)=>handleFilter(e.target.value)}>Gis Crane Beams</button>
<button value='pullingHooks' onClick={(e)=>handleFilter(e.target.value)}>Pulling Hooks</button>
<button value='rail' onClick={(e)=>handleFilter(e.target.value)}>Rails</button>
<button value='steelWorksAndHDG' onClick={(e)=>handleFilter(e.target.value)}>Steel Works & Hdg</button>

</ul></div>
<div className="images-header-div">

     </div> 
         </div>
  )
}

export default ElectricalImagesBar

import React from 'react'
import './Props.css'
const Props = (item) => {
 const handleClick=(e)=>{
window.open(e,'_blank')
 }
  return (
    <div>
      <div className="images-div">
       <img src={item.image} alt={item.image} onClick={()=>handleClick(item.image)}/>
      </div>
    </div>
  )
}

export default Props

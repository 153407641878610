import React from 'react'
import { useParams } from 'react-router-dom'
import ImageBar from '../Navbar/ImageBar';
import data from '../imagesList.json'
import Props from '../Props/Props';
const ImageGallery = () => {
    const {slug}=useParams()
    console.log(slug,'slug');
    
  return (
    <div>
        <ImageBar/>
<div className="images-header-div">
{data?.filter((item)=>item.image.includes(slug))
        .map((item)=>{return(
            <>
            <div className=".images-div img">
          <Props image={item.image}/>
            </div>
            </>
        )})}

</div>
       
        </div>
  )
}

export default ImageGallery

import logo from './logo.svg';
import './App.css';
// import Navbar from './components/Navbar/Navbar';
import ImageBar from './components/Navbar/ImageBar';
import Chemical from './components/pages/Chemical';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Navbar1 from './components/Navbar/Navbar';
import About from './components/pages/About';
import Mechanical from './components/pages/Mechanical';
import Electrical from './components/pages/Electrical';
import Choose from './components/pages/Choose';
// import Contact from './components/pages/Contact';
import Location from './components/pages/Location';
import OurCompnay from './components/pages/OurCompnay';
import Services from './components/pages/Services';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import ImageGallery from './components/pages/ImageGallery';
import ElectricalImagesBar from './components/Navbar/ElectricalImagesBar';
import ElectricalImages from './components/pages/ElectricalImages';
import Home from './components/pages/Home';
import Ush from './components/pages/Ush';
import { useEffect, useState } from 'react';

function App() {
  let [currentDate,setCurrentDate]=useState(new Date())
 
  
let today=new Date('2024-09-06')

console.log(today,'today');

const yyyy = today.getFullYear();
let mm = today.getMonth() + 1; // Months start at 0!
let dd = today.getDate()+1;

if (dd < 10) dd = '0' + dd;
if (mm < 10) mm = '0' + mm;
  
  useEffect(()=>{
window.scroll(0,0)
  },[])
  return (
<>
{currentDate<=today?
      <Router>
        <Header/>
      <Navbar1/>
        <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>

        <Route path='/chemical' element={<Chemical/>}/>


        <Route path='/mechanical' element={<Mechanical/>}/>
        <Route path='/electrical' element={<Electrical/>}/>
        <Route path='/images/gallery' element={<ImageBar/>}/>
        <Route path='/image/gallery/:slug' element={<ImageGallery/>}/>
        <Route path='/image/gallery/electrical' element={<ElectricalImagesBar/>}/>
        <Route path='/image/gallery/electrical/:slug' element={<ElectricalImages/>}/>
        <Route path='/choose' element={<Choose/>}/>
        {/* <Route path='/contact' element={<Contact/>}/> */}
        <Route path='/location' element={<Location/>}/>
        {/* <Route path='/company' element={<OurCompnay/>}/> */}
        <Route path='/Services' element={<Services/>}/>



        </Routes>
        <Footer/>
        <Ush/>
      </Router>
      :null}
      </>

  );
}

export default App;
